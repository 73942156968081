import Navbar from "../Navbar/Navbar";
import Start from "./Start";

function CharityResources() {
    return(
        <div>
            <Navbar />
            <Start />
        </div>
    )
}

export default CharityResources;